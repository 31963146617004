<template>
    <v-sheet :disabled="loading">
        <v-card
            outlined
            hover
            class="d-flex align-center mb-4 pa-4"
            @click="$emit('input', { target: 'COM_BACK' })"
        >
            <div>
                <w-icon
                    color="primary"
                    value="COM_BACK"
                />
            </div>
            <div class="pl-4">
                <div>{{ roleLabel }}</div>
                <div class="caption">{{ roleHint }}</div>
            </div>
        </v-card>
        <v-autocomplete
            v-model="selectedResourceId"
            :items="foundResourcesComputed"
            :loading="isSearchingResource"
            :disabled="isSearchingResource"
            :search-input.sync="searchResource"
            outlined
            hide-no-data
            hide-selected
            hide-details
            clearable
            item-text="resource_title"
            item-value="resource_id"
            :label="label"
            :placeholder="$trans('Start typing to Search')"
            @click:clear="foundResources = []"
        >
            <template v-slot:item="{ item, attrs, on }">
                <v-list-item dense v-bind="attrs" v-on="on" class="my-2">
                    <v-list-item-content>
                        <v-list-item-title>{{ item['resource_title'] }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item['resource_hint'] }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-autocomplete>
        <div class="d-flex justify-end mt-4">
            <v-btn
                :disabled="!selectedResourceId"
                color="success"
                @click="onSubmit"
            >
                {{ $trans('Save') }}
            </v-btn>
        </div>
    </v-sheet>
</template>

<script>
import { fetchAccessResourceCollection } from '@apps/employee/api/access'
import hasCurrentSchoolPropertyMixin from '@apps/school/mixins/hasCurrentSchoolPropertyMixin'
import Debugger from '@/utils/Debugger'

export default {
    name: 'AccessResourceField',
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        role: {
            type: String,
            required: true
        },
        memberId: {
            type: String,
            default: null
        },
        memberType: {
            type: String,
            default: null
        },
        roles: {
            type: Object,
            required: true
        }
    },
    mixins: [
        hasCurrentSchoolPropertyMixin
    ],
    data() {
        return {
            isSearchingResource: false,
            searchResource: null,
            selectedResourceId: null,
            foundResources: []
        }
    },
    computed: {
        roleLabel() {
            return this.roles[this.role].label
        },
        roleHint() {
            return this.roles[this.role].hint
        },
        label() {
            return this.roles[this.role].resource
        },
        foundResourcesComputed() {
            if(this.$auth.isSuperAdmin()) {
                return this.foundResources
            }

            if(!this.currentSchool) return []

            return this.foundResources.filter(o => {
                return o.resource_relations.school.uuid === this.currentSchool.uuid
            })
        }
    },
    watch: {
        searchResource(val) {
            if (!val || (val && val.length < 2)) {
                this.isSearchingResource = false
                return
            }

            // Items have already been loaded
            if (this.foundResources.length > 0) return

            // Items have already been requested
            if (this.isSearchingResource) return

            this.isSearchingResource = true

            // Lazily load input items
            setTimeout(() => {
                fetchAccessResourceCollection({
                    role: this.role,
                    searchBy: val
                })
                    .then(response => {
                        this.foundResources = [].concat(response.data.data)
                    })
                    .catch(err => {
                        Debugger.log(err)
                    })
                    .finally(() => (this.isSearchingResource = false))
            }, 300)
        }
    },
    methods: {
        onSubmit() {
            const resource = this.foundResources.find(o => o.resource_id === this.selectedResourceId)
            const data = {
                role: this.role,
                resource_id: this.selectedResourceId,
                resource_type: resource.resource_type,
                member_id: this.memberId,
                member_type: this.memberType
            }

            this.$emit('submit', data)
        }
    }
}
</script>

<style lang=scss>

</style>
